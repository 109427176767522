body{
  background-color: #32211A;
  color: #FFF;
  height: 100%;
}

html{
  height: 100%;
}

.App {
  background-color: #32211A;
  height: 100%;
}

#root{
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #32211A;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.MuiAvatar-root{
  background: #FFF;
  border: 1px solid #CACACA;
}

.row {
  display: flex;
  flex-direction: row;
}

.column{
  display: flex;
  flex-grow: 1;
}

.fullHeight{
  height: 100%;
  box-sizing: border-box;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.react-transform-component {
  width: 100%;
  height: 100%;
}